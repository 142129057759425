import 'dayjs/locale/en-gb';
import dsTranslations from '@loveholidays/design-system/lib/translations/en-GB.json';

import translations from './main.json';

export const namespaces = {
  // Empty string is the default namespace in @loveholidays/phrasebook:
  // https://github.com/loveholidays/phrasebook/blob/main/src/constants.ts#L1
  '': translations,
  lvhds: dsTranslations,
};
